.wrap {
  width: 40px;

  button {
    width: 40px;
    min-width: 40px;
    box-sizing: border-box;
    padding: 7px !important;
  }
}
